import { motion } from 'framer-motion';
import { Content } from '../../components/Content';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { PageContainer } from '../../components/PageContainer';
import { SectionsList } from '../../components/SectionsList';
import { useMiscData } from '../../hooks/useHeaderData';

export const Menu = () => {
    const data = useMiscData();
    return (
        <motion.div
            initial={{ x: '-100%', opacity: 0.5 }}
            animate={{ x: '0%', opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <PageContainer className="min-h-screen h-auto">
                <Header title={data.headline} desc={data.greetingMessage} image={data.coverImage} />
                <Content>
                    <SectionsList />
                </Content>
                <Footer />
            </PageContainer>
        </motion.div>
    );
};
