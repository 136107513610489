import { ScrollArea } from '@mantine/core';
import { useDebounceFn } from 'ahooks';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useToSlug } from '../../../hooks/useToSlug';

export const CategoriesTags = ({
    tags,
    visibleSection,
}: {
    tags: any[];
    visibleSection: string;
}) => {
    const viewportRef = useRef<HTMLDivElement | null>(null);
    const toHash = useToSlug({
        withHash: true,
    });
    const isActive = (name: string) => toHash(name) == toHash(visibleSection);
    const { run } = useDebounceFn(
        () => {
            if (viewportRef.current && visibleSection) {
                const activeTagElement = viewportRef.current.querySelector(
                    `a[href='${toHash(visibleSection)}']`,
                );
                const activeTagElementX = activeTagElement?.getBoundingClientRect().left || 0;
                const activeTagElementScroll = viewportRef.current?.scrollLeft || 0;
                const leftScroll = activeTagElementScroll + activeTagElementX;
                console.log(visibleSection, leftScroll);
                viewportRef.current.scrollTo({ left: leftScroll, behavior: 'smooth' });
            }
        },
        { wait: 200 },
    );
    useEffect(() => {
        run();
    }, [run, visibleSection]);
    return (
        <div className="overflow-hidden relative after:content-[''] after:absolute after:w-10 after:right-0 after:inset-y-0 after:bg-gradient-to-r after:from-transparent after:to-white before:content-[''] before:absolute before:w-8 before:left-0 before:inset-y-0 before:bg-gradient-to-l before:from-transparent before:to-white">
            <ScrollArea viewportRef={viewportRef}>
                <div className="flex items-center pt-6 pb-3">
                    {tags.map(({ name }) => (
                        <a
                            href={toHash(name)}
                            key={name}
                            className={clsx(
                                'flex-none ml-3 last:mr-3 px-4 py-2 rounded-3xl text-brand-brownDark font-medium',
                                {
                                    'bg-brand-brown': isActive(name),
                                    'bg-brand-brownLight': !isActive(name),
                                },
                            )}
                        >
                            {name}
                        </a>
                    ))}
                </div>
            </ScrollArea>
        </div>
    );
};
