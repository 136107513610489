import clsx from 'clsx';
import { CategoryItem } from './CategoryItem';

export const Category = ({
    name,
    id,
    items = [],
    className,
}: {
    name: string;
    items: any[];
    id: string;
    className: string;
}) => {
    return (
        <div id={id} className={clsx('text-brand-black py-2', className)}>
            {/* title  */}
            <h2 className="text-xl font-bold">{name}</h2>
            {/* items  */}
            <div className="py-2 space-y-2">
                {items.map((item, i) => (
                    <CategoryItem key={i} name={item.Article} price={item.Price} />
                ))}
            </div>
        </div>
    );
};
