import { ScrollArea } from '@mantine/core';
import clsx from 'clsx';
import { useWatchScroll } from '../../../hooks/useWatchScroll';
import { useToSlug } from '../../../hooks/useToSlug';
import { Category } from './Category';

export const Categories = ({
    categories = {},
    setVisibleSection,
}: {
    categories: any;
    setVisibleSection: (id: string) => void;
}) => {
    const { onScroll, containerRef } = useWatchScroll({
        sectionsSelector: '.category',
        onSectionVisibilityChange: setVisibleSection,
    });
    const categoriesNames = Object.keys(categories);
    const toSlug = useToSlug();
    return (
        <ScrollArea
            viewportRef={containerRef}
            onScrollPositionChange={onScroll}
            className={clsx(
                'relative flex flex-col flex-1 overflow-auto scroll-smooth',
                "after:content-[''] after:absolute after:h-5 after:top-0 after:inset-x-0 after:bg-gradient-to-t after:from-transparent after:to-white ",
                "before:content-[''] before:absolute before:h-12 before:bottom-0 before:inset-x-0 before:bg-gradient-to-b before:from-transparent before:to-white",
            )}
        >
            <div className={clsx('px-4 pt-4 flex-1 flex flex-col overflow-auto scroll-smooth')}>
                {categoriesNames.map((categoryName) => (
                    <Category
                        className="category"
                        id={toSlug(categoryName)}
                        key={categoryName}
                        name={categoryName}
                        items={categories[categoryName]}
                    />
                ))}
            </div>
        </ScrollArea>
    );
};
