import { get } from 'lodash';
import { useMemo } from 'react';
import { useStore } from '../store';

export const useMiscData = () => {
    const allData = useStore((state) => state.data);
    const data = useMemo(() => {
        return {
            headline: get(allData, 'headline'),
            coverImage: get(allData, 'cover image'),
            greetingMessage: get(allData, 'greeting message'),
            footerNotes: get(allData, 'footer notes'),
            mainColor: get(allData, 'main color'),
        };
    }, [allData]);
    return data;
};
