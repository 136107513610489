import clsx from 'clsx';
import cover from '../../assets/images/cover.jpg';
import { BackButton } from './BackButton';
import { LanguageSwitch } from './LanguageSwitch';
export const Header = ({
    title,
    desc,
    image,
    variant = 'large',
    showBackButton = false,
}: {
    title?: string;
    desc?: string;
    image?: string;
    variant?: 'large' | 'small';
    showBackButton?: boolean;
}) => {
    const isLarge = variant === 'large';
    return (
        <div
            className={clsx('relative flex flex-col text-white px-4 py-5 bg-cover bg-center', {
                'min-h-[344px]': isLarge,
                'min-h-[200px]': !isLarge,
            })}
            style={{ backgroundImage: `url(${image || cover})` }}
        >
            {/* overlay */}
            <div className="bg-black/50 absolute inset-0"></div>
            {/* top bar  */}
            <div className="relative flex justify-between mb-4">
                {showBackButton ? <BackButton /> : <span></span>}
                <LanguageSwitch />
            </div>
            {/* title and desc  */}
            <div className="relative text-center my-auto pb-16">
                <h1 className="text-3xl font-bold mb-2">{title}</h1>
                <p className="text-base max-w-xs mx-auto">{desc}</p>
            </div>
        </div>
    );
};
