import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { Content } from '../../components/Content';
import { Header } from '../../components/Header';
import { PageContainer } from '../../components/PageContainer';
import { SectionDetails } from '../../components/SectionDetails';
import { useMiscData } from '../../hooks/useHeaderData';

export const Section = () => {
    const { section } = useParams();
    const data = useMiscData();
    return (
        <motion.div
            initial={{ x: '100%', opacity: 0.5 }}
            animate={{ x: '0%', opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <PageContainer>
                <Header variant="small" showBackButton title={section} image={data.coverImage} />
                <Content>
                    <SectionDetails section={section} />
                </Content>
            </PageContainer>
        </motion.div>
    );
};
