import { Alert, LoadingOverlay } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons';
import { useEffect } from 'react';
import { API } from '../../config/API';
import { useStore } from '../../store';

export const FetchingDataGate = ({ children }: { children: JSX.Element }) => {
    const getData = useStore((state) => state.getData);
    const loading = useStore((state) => state.loading);
    const error = useStore((state) => state.error);
    useEffect(() => {
        getData();
        useStore.persist.setOptions({
            name: API.getMenuId(),
        });
    }, [getData]);
    return (
        <>
            {loading ? (
                <LoadingOverlay visible />
            ) : error ? (
                <Alert color="red" icon={<IconAlertCircle />}>
                    {error?.message}
                </Alert>
            ) : (
                children
            )}
        </>
    );
};
