import { useSections } from '../../hooks/useSections';
import { CategoriesTags } from './CategoriesTags';
import { Categories } from './Categories';
import { map } from 'lodash';
import { useState } from 'react';

export const SectionDetails = ({ section }: { section?: string }) => {
    const { getSectionCategories } = useSections();
    const categories = getSectionCategories(section);
    const [visibleSection, setVisibleSection] = useState('');
    const tags = map(Object.keys(categories), (name, i) => ({ name, isActive: i === 0 }));
    return (
        <div className="flex-1 max-h-full flex flex-col">
            <CategoriesTags tags={tags} visibleSection={visibleSection} />
            <Categories categories={categories} setVisibleSection={setVisibleSection} />
        </div>
    );
};
