const getBaseUrl = () => {
    const url = 'https://dash.getmenyo.com';
    return url;
};
const getApiUrl = () => '/api/menu';
const getMenuId = () => location.pathname.split('/')[1];
const getFullApiUrl = () => `${getBaseUrl()}${getApiUrl()}/${getMenuId()}`;
const getDataByLang = (lang = '') => `${getFullApiUrl()}${lang ? `/${lang}` : ''}`;
export const API = {
    getMenuId,
    getDataByLang,
};
