import { Outlet } from 'react-router-dom';
import { FetchingDataGate } from '../../gates/FetchingDataGate';
import { LanguageSelectionGate } from '../../gates/LanguageSelectionGate';

export const AppLayout = () => {
    return (
        <FetchingDataGate>
            <LanguageSelectionGate>
                <Outlet />
            </LanguageSelectionGate>
        </FetchingDataGate>
    );
};
