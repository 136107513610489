import { IconError404 } from '@tabler/icons';

export const NotFound = () => {
    return (
        <div className="p-6 h-screen text-gray-500 flex flex-col justify-center items-center">
            <IconError404 size={120} />
            <p className="text-center font-bold text-4xl">Not Found</p>
        </div>
    );
};
