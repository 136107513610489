export const CategoryItem = ({ name, price }: { name: string; price: string }) => {
    return (
        <div className="flex justify-between space-x-8 text-brand-black border-b border-dashed pb-2">
            {/* name  */}
            <p className="text-base">{name}</p>
            {/* price  */}
            <p className="font-bold uppercase">{price}</p>
        </div>
    );
};
