import { useStore } from '../../store';
import frIcon from '../../assets/icons/french.svg';
import enIcon from '../../assets/icons/english.svg';
import spIcon from '../../assets/icons/spanish.svg';
import langIcon from '../../assets/icons/lang.svg';
import closeIcon from '../../assets/icons/close.svg';
import { toLower } from 'lodash';
import clsx from 'clsx';
import { Modal } from '@mantine/core';
import { PoweredBy } from '../Footer/PoweredBy';

export const LanguagePicker = ({
    onSelect = () => {},
    onClose = () => {},
    opened,
}: {
    onSelect?: () => void;
    onClose?: () => void;
    opened: boolean;
}) => {
    const languages = useStore((state) => state.languages);
    const selectedLanguage = useStore((state) => state.selectedLanguage);
    const setSelectedLanguage = useStore((state) => state.setSelectedLanguage);
    const languagesAssets: any = {
        fr: {
            icon: frIcon,
            name: 'Français',
        },
        en: {
            icon: enIcon,
            name: 'English',
        },
        es: {
            icon: spIcon,
            name: 'Español',
        },
    };
    const getName = (lang: string) => languagesAssets[toLower(lang)]?.name || lang;
    const getIcon = (lang: string) => languagesAssets[toLower(lang)]?.icon;
    const isSelected = (lang: string) => toLower(lang) === toLower(selectedLanguage || '');
    return (
        <Modal
            styles={{
                body: {
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                },
            }}
            onClose={onClose}
            trapFocus={false}
            opened={opened}
            fullScreen
            centered
            withCloseButton={false}
            exitTransitionDuration={100}
        >
            <div className="flex flex-col items-center space-y-3">
                {/* close  */}
                <div className="w-full flex justify-end">
                    <button
                        onClick={onClose}
                        className="text-brand-brown border w-8 h-8 flex justify-center items-center border-brand-blueLight p-1 rounded-full"
                    >
                        <img className="w-[13px] h-[13px]" src={closeIcon} alt="icon" />
                    </button>
                </div>
                <div className="pt-16" />
                {/* icon  */}
                <div className="rounded-full w-20 h-20 flex justify-center items-center border border-brand-blueLight">
                    <img className="w-9" src={langIcon} alt="icon" />
                </div>
                {/* title  */}
                <h2 className="text-3xl text-center font-bold pb-4">Choose a language</h2>
                {/* options  */}
                {languages.map((lang) => (
                    <button
                        onClick={() => {
                            setSelectedLanguage(lang);
                            onSelect();
                        }}
                        className={clsx(
                            'p-1 pr-5 text-xl font-bold flex items-center space-x-2 text-brand-black rounded-full',
                            {
                                'bg-brand-brownLight': isSelected(lang),
                            },
                        )}
                        key={lang}
                    >
                        <img src={getIcon(lang)} alt="icon" />
                        <span>{getName(lang)}</span>
                    </button>
                ))}
            </div>
            <div className="text-center">
                <PoweredBy />
            </div>
        </Modal>
    );
};
