import { useSections } from '../../hooks/useSections';
import { SectionItem } from './SectionItem';

export const SectionsList = () => {
    const { sections } = useSections();
    return (
        <div className="px-8 py-16 flex flex-col space-y-8">
            {sections.map((section, i) => (
                <SectionItem key={i} name={section} />
            ))}
        </div>
    );
};
