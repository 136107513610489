import { isEmpty } from 'lodash';
import { LanguagePicker } from '../../components/LanguagePicker';
import { useStore } from '../../store';

export const LanguageSelectionGate = ({ children }: { children: JSX.Element }) => {
    const selectedLanguage = useStore((state) => state.selectedLanguage);

    return (
        <>
            {children}
            <LanguagePicker opened={isEmpty(selectedLanguage)} />
        </>
    );
};
