import { useMiscData } from '../../hooks/useHeaderData';
import { FooterNote } from './FooterNote';
import { PoweredBy } from './PoweredBy';

export const Footer = () => {
    const data = useMiscData();
    return (
        <div className="mt-auto p-4 flex flex-col space-y-2 items-center">
            <FooterNote note={data.footerNotes} />
            <PoweredBy />
        </div>
    );
};
