import { useEffect, useRef, useState } from 'react';

export const useWatchScroll = ({
    sectionsSelector,
    onSectionVisibilityChange = () => {},
}: {
    sectionsSelector: string;
    onSectionVisibilityChange?: (sectionId: string) => void;
}) => {
    const [currentSectionID, setCurrentSectionID] = useState('');
    const containerRef = useRef<HTMLDivElement | null>(null);
    const onScroll = () => {
        const sections = containerRef.current?.querySelectorAll(sectionsSelector);
        const getSectionIdInRange = ({ nodes = [] }: { nodes?: HTMLElement[] | any }) => {
            const containerTop = Math.round(
                containerRef.current?.getBoundingClientRect()?.top || NaN,
            );
            const containerBottom = Math.round(
                containerRef.current?.getBoundingClientRect().bottom || NaN,
            );
            let sectionIdInRange;
            for (let index = 0; index < nodes.length; index++) {
                const element = nodes[index];
                const elementId = element.getAttribute('id');
                const elementTop = Math.round(element.getBoundingClientRect().top);
                const elementBottom = Math.round(element.getBoundingClientRect().bottom);
                // compare to find the real one in range
                if (index === 0) {
                    console.log(elementId, containerTop, elementTop);
                }
                if (
                    (containerTop >= elementTop && containerTop <= elementBottom) ||
                    (containerBottom >= elementTop && containerBottom - 5 <= elementBottom)
                ) {
                    sectionIdInRange = elementId;
                }
                // when a section's title is very close to the top, the related tag should be active
                if (containerTop - 5 <= elementTop && containerTop + 5 >= elementTop) {
                    sectionIdInRange = elementId;
                    break;
                }
            }
            return sectionIdInRange || '';
        };
        setCurrentSectionID(getSectionIdInRange({ nodes: sections }));
    };
    useEffect(() => {
        onSectionVisibilityChange(currentSectionID);
    }, [currentSectionID, onSectionVisibilityChange]);
    return {
        onScroll,
        currentSectionID,
        containerRef,
    };
};
