import axios from 'axios';
import { map, uniq } from 'lodash';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { API } from '../config/API';

export interface StoreSlicesInterface {
    loading: boolean;
    error: any;
    data: any;
    languages: string[];
    selectedLanguage: string | null;
    setSelectedLanguage: (selectedLanguage: string) => void;
    getData: () => void;
}
export const useStore = create<StoreSlicesInterface>()(
    persist(
        (set) => ({
            loading: false,
            error: null,
            data: null,
            languages: [],
            selectedLanguage: null,
            setSelectedLanguage: (selectedLanguage: string) => set(() => ({ selectedLanguage })),
            getData: async () => {
                try {
                    set(() => ({ loading: true, error: null }));
                    const res = await axios.get(API.getDataByLang());
                    const data = res.data;
                    if (data?.error) throw Error(data?.error?.message);
                    const languages: string[] = uniq(map(data.rows, (row: any) => row.Language));
                    set(() => ({ data, languages }));
                } catch (error) {
                    set(() => ({ error }));
                } finally {
                    set(() => ({ loading: false }));
                }
            },
        }),
        { name: API.getMenuId() },
    ),
);
