import { Link } from 'react-router-dom';
import backIcon from '../../../assets/icons/back.svg';
import { API } from '../../../config/API';
import { paths } from '../../../Router/paths';
export const BackButton = () => {
    return (
        <Link
            to={paths.HOME(API.getMenuId())}
            className="p-0 w-10 h-10 bg-white rounded-full flex justify-center items-center"
        >
            <img src={backIcon} alt="back-icon" />
        </Link>
    );
};
