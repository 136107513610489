import { useState } from 'react';
import langIcon from '../../../assets/icons/lang.svg';
import { LanguagePicker } from '../../LanguagePicker';

export const LanguageSwitch = () => {
    const [opened, setOpened] = useState(false);
    return (
        <div className="flex space-x-2 items-center">
            <button
                onClick={() => setOpened(true)}
                className="p-0 w-[42px] h-[42px] bg-white rounded-full flex justify-center items-center"
            >
                <img src={langIcon} alt="lang-icon" />
            </button>
            <LanguagePicker
                opened={opened}
                onClose={() => setOpened(false)}
                onSelect={() => setOpened(false)}
            />
        </div>
    );
};
