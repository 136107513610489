import { chain, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useStore } from '../store';

export const useSections = () => {
    const rows = useStore((state) => state.data?.rows);
    const selectedLanguage = useStore((state) => state.selectedLanguage);
    const sections = useMemo(() => {
        return chain(rows)
            .filter(['Language', selectedLanguage])
            .uniqBy('Section')
            .map((row) => row.Section)
            .valueOf();
    }, [rows, selectedLanguage]);
    const getSectionCategories = (section?: string) => {
        const rowsBySection = chain(rows)
            .filter(['Language', selectedLanguage])
            .filter(['Section', section])
            .valueOf();

        const categories: { [key: string]: any[] } = {};

        for (let index = 0; index < rowsBySection.length; index++) {
            const row = rowsBySection[index];
            const categoryName = row.Category;
            if (isEmpty(categories[categoryName])) {
                categories[categoryName] = [row];
            } else {
                categories[categoryName].push(row);
            }
        }
        return categories;
    };
    return { sections, getSectionCategories };
};
