import clsx from 'clsx';

export const PageContainer = ({
    children,
    className,
}: {
    children: JSX.Element | JSX.Element[];
    className?: string;
}) => {
    return <div className={clsx('h-screen flex flex-col', className)}>{children}</div>;
};
