import { Input } from '@mantine/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PageContainer } from '../../components/PageContainer';
import { paths } from '../../Router/paths';

export const IdPage = () => {
    const [id, setId] = useState('');
    console.log('test...');
    return (
        <PageContainer className="flex flex-col justify-center items-center">
            <div className="p-2 w-full max-w-sm flex flex-col space-y-2">
                <Input
                    value={id}
                    name="id"
                    autoComplete="on"
                    onChange={(e: any) => setId(e.target.value)}
                    className="flex-auto"
                    placeholder="ENTER MENU ID"
                />
                <Link
                    to={paths.HOME(id)}
                    className="text-brand-black text-center bg-brand-brownLight p-1 rounded"
                >
                    Go!
                </Link>
            </div>
        </PageContainer>
    );
};
