import { Link } from 'react-router-dom';
import chevronRight from '../../../assets/icons/chevron-right.svg';
import { API } from '../../../config/API';
import { paths } from '../../../Router/paths';
export const SectionItem = ({ name }: { name: string }) => {
    return (
        <div className="">
            <Link
                to={paths.SECTION(API.getMenuId(), name)}
                className="inline-flex items-center space-x-3"
            >
                <p className="text-brand-black font-bold text-xl">{name}</p>
                <button className="w-8 h-8 rounded-full flex justify-center items-center bg-brand-brown">
                    <img src={chevronRight} alt="icon" />
                </button>
            </Link>
        </div>
    );
};
