import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Section } from '../pages/Section';
import { Menu } from '../pages/Menu';
import { paths } from './paths';
import { AppLayout } from '../layouts/AppLayout';
import { IdPage } from '../pages/IdPage';
import { NotFound } from '../pages/NotFound';
import { ScrollToTop } from '../components/ScrollToTop';

export const Router = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<IdPage />} />
                <Route path={paths.HOME()} element={<AppLayout />}>
                    <Route index element={<Menu />} />
                    <Route path={paths.SECTION()} element={<Section />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};
